
import Vue from 'vue'
import {
  CollectionUrl,
  sermonRootUrl,
  siteScriptureUrl,
  siteSermonDateUrl,
} from '~/assets/ts/utils/urls'
import SiteNavGroupSubnav from '~/components/site/nav/group/Subnav.vue'
import SiteNavLi from '~/components/site/nav/Li.vue'
import { Collection } from '~/models/collection'
import SiteNavGroupHeader from '~/components/site/nav/group/Header.vue'
import HorizontalRule from '~/components/_general/HorizontalRule.vue'
import LoadingElement from '~/components/_general/LoadingElement.vue'
import { getListWithPlaceholders } from '~/assets/ts/utils/lists'

export default Vue.extend({
  name: 'SiteNavGroupSermons',
  components: {
    LoadingElement,
    HorizontalRule,
    SiteNavGroupHeader,
    SiteNavLi,
    SiteNavGroupSubnav,
  },
  props: {
    active: {
      type: Boolean,
    },
  },
  data() {
    return {
      fetched: false,
      collections: [] as Collection[],
    }
  },
  computed: {
    scriptureUrl(): string {
      return siteScriptureUrl()
    },
    sermonsUrl(): string {
      return sermonRootUrl
    },
    sermonsDateUrl(): string {
      return siteSermonDateUrl()
    },
    staffPicksUrl(): string {
      return `${this.sermonsUrl}/staff-picks`
    },
    featuredUrl(): string {
      return `${this.sermonsUrl}/featured`
    },
    videosUrl(): string {
      return `${this.sermonsUrl}/videos`
    },
    clipsUrl(): string {
      return `${this.sermonsUrl}/clips`
    },
    transcriptsUrl(): string {
      return `${this.sermonsUrl}/transcripts`
    },
    featuredCollections(): Collection[] {
      return getListWithPlaceholders(this.collections, 3)
    },
  },
  watch: {
    active() {
      if (this.fetched || !this.active) return
      this.getCollections()
    },
  },
  methods: {
    CollectionUrl,
    navigate() {
      this.$emit('navigate')
    },
    async getCollections() {
      const collections = await this.$apiClient.getMenuCollections()
      this.collections = collections.results
      this.fetched = true
    },
  },
})
